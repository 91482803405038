@import 'src/styles/color';
@import 'src/styles/mixin';
@import 'src/styles/typography';
@import 'src/styles/constants';

$MOBILE_REGULAR_BUTTON_TOP_MARGIN: 12px;

.field {
  @include flex-column;

  gap: 8px;

  &.error .fieldInput {
    &, &:focus, &:active {
      border-color: $red_error-500;
      outline-color: $red_error-500;
    }
  }
}

.inputContainer {
  position: relative;
}

.formFieldWrapper {
  position: relative;

  & .hasCloseBtn {
    padding-right: 36px;
  }

  & .closeBtn {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}

.fieldInput {
  // TODO: get rid of this
  @extend .supportive-14;

  width: 100%;
  height: $MOBILE_INPUT_HEIGHT;
  padding: 8px 12px;
  border: 1px solid $grey_focused-stroke-scroll-bar;
  border-radius: 8px;
  background-color: $grey_main-BG-stroke;
  color: $black_main-text;
  outline: none;
  resize: none;

  &:focus, &:active, &:focus-visible {
    border: 1px solid $blue_main-400;
  }

  &:disabled {
    background-color: $grey_focused-stroke-scroll-bar;
    color: $grey_caption-disabled-text;
  }
}

.disabled {
  color: $grey_caption-disabled-text !important;
}

.fieldError {
  @include flex-row;

  // TODO: get rid of this
  @extend .bodySizeM;

  align-items: center;
  color:  $red_error-500;
  column-gap: 8px;

  svg {
    path {
      stroke: $red_error-500;
    }
  }
}

.positionLeft {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.fieldInfo {
  @include flex-row;

  justify-content: space-between;
  color: $grey_caption-disabled-text;
  gap: 8px;
}

.password {
  position: relative;
  width: 100%;
}

.checkbox {
  @include flex-row-center;

  margin-right: auto;
}

.text {
  padding-left: 4px;
  color: $black_main-text;
}

.submitError {
  width: 100%;
  margin: 16px 0;
  color: $red_error-500;
  text-align: center;
}

.eyeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  background-color: transparent;
  color: $grey_secondary-text;
}

.passwordInput {
  padding-right: 40px;
}

.toggleContainer {
  margin: 10px 0;
}

.verifyButton{
  width: 100%;
  margin-top: $MOBILE_REGULAR_BUTTON_TOP_MARGIN;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .fieldInput {
    // TODO: get rid of this
    @include bodySizeM;

    height: $INPUT_HEIGHT;
    background-color: $white_BG;

    &:disabled {
      background-color: $grey_disabled-BG;
    }
  }

  .fieldInfo {
    gap: 62px;
  }

  .wrapper {
    position: relative;
  }
  
  .verifyButton {
    position: absolute;
    top: $BASE_PADDING;
    left: calc(100% + $BASE_PADDING);
    width: max-content;
  }
}
