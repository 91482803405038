@import 'src/styles/color.scss';
@import 'src/styles/mixin.scss';
@import 'src/styles/constants.scss';

.wrapperChanel {
  display: grid;
  grid-template-rows: auto 1fr auto;

  overflow: hidden;
  height: 100%;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .wrapperChanel {
    height: inherit;
    max-height: inherit;
    transform: unset;
  }
}

