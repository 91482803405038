@import 'src/styles/constants';

.modalContainer {
  width: 100%;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .modalContainer {
    width: 366px;
  }
}