@import 'src/styles/mixin.scss';
@import 'src/styles/color.scss';
@import 'src/styles/typography.scss';
@import 'src/styles/responsive.scss';

.modalContainer {
  height: 100%;
  width: 100%;
  @include mobile-paddings;
}

.formRadio {
  @include bodySizeM;
  gap: 20px;
  color: $black_main-text;
}

.buttonRow {
  @include flex-column;
  gap: 12px;
}

.successContainer {
  width: 100%;
}

@media screen and (min-width: $DESKTOP-BREAKPOINT) {
  .modalContainer {
    width: 472px;
    padding: 0;
  }

  .successContainer {
    width: 366px;
  }
}
